import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-14">
            <h1>Tips voor de behandeling van de symptomen van diarree</h1>
            <div className="img">
              <img
                src="/assets/files/pages/durchfallmythen.jpeg"
                width="701"
                height="289"
                alt="Tips voor behandeling"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Tips voor de behandeling van diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  Over de behandeling van diarree zijn er nog veel misverstanden
                  en onzekerheden.&nbsp;
                </p>
                <p>Lees hier enkele belangrijke tips:</p>
              </div>
            </div>
            <h3>Tip 1: Voorkom uitdroging</h3>
            <p>
              De belangrijkste eerste stap in geval van diarree is om ervoor te
              zorgen dat er voldoende vocht wordt ingenomen. Zorg ervoor dat je
              voldoende water drinkt, best 2 tot 3 liter per dag. Eventueel kan
              het nuttig zijn om ook extra mineralen in te nemen.
            </p>
            <h3>Tip 2: Wacht niet te lang met de behandeling van diaree</h3>
            <p>
              Veel mensen geloven dat <strong>diarree</strong> nuttig is om de
              bacteriën die de diarree veroorzaken uit het lichaam weg te
              spoelen. De ziekteverwekkers worden echter niet bestreden door de
              diarree, maar wel door het lichaamseigen immuunsysteem. De diarree
              is alleen maar een symptoom. Vaak wordt diarree zelfs niet eens
              door bacteriën, maar door andere,{" "}
              <a href="/feiten-over-diarree/stress-en-diarree/">
                niet besmettelijke oorzaken
              </a>
              &nbsp;uitgelokt. Of bacteriën er nu wel of niet bij betrokken
              zijn, <a href="/imodium-behandelen-diarree/">IMODIUM®</a> kan de
              symptomen van diarree snel verlichten, terwijl je eigen
              immuunsysteem de infectie verder bestrijdt. Daardoor voel je je
              beter en wordt je normale dagelijkse leven minder beïnvloed.
            </p>
            <h3>
              Tip 3: Diarree bij kinderen: een snelle behandeling is uiterst
              belangrijk
            </h3>
            <p>
              Diarree onttrekt waardevol vocht en voedingsstoffen aan je
              lichaam. Kinderen kunnen dit vochttekort minder goed verdragen dan
              volwassenen. Daarom is het belangrijk om bij{" "}
              <strong>diarree bij kinderen</strong>&nbsp;het vocht snel aan te
              vullen en de <strong>diarree te stoppen</strong>. Bij kinderen
              vanaf 8 jaar mogen de symptomen van diarree met{" "}
              <a href="/imodium-behandelen-diarree/imodium-instant/">
                IMODIUM® Instant smelttablet&nbsp;
              </a>
              of{" "}
              <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                IMODIUM® Capsules
              </a>{" "}
              behandeld worden. Als het probleem aanhoudt, moet de arts
              geraadpleegd worden. Zeker met baby’s of hele kleine kinderen ga
              je best langs de arts in geval van diarree. Lees ook de informatie
              over <a href="/diarree-bij-kinderen/">diarree bij kinderen</a>.
            </p>
            <h3>
              Tip 4: Zorg ervoor dat de overmatige darmactiviteit snel terug
              normaliseert
            </h3>
            <p>
              Het gebruik van IMODIUM® zal bijdragen om de darmactiviteit terug
              te brengen tot het normale, natuurlijke ritme, zonder de normale
              darmfuncties te beïnvloeden. Bedenk wel: door de diarree is je
              darm vaak helemaal leeg. Het kan dus één of twee dagen duren,
              voordat hij weer gevuld is.&nbsp; Daardoor kun je de indruk
              krijgen dat je last hebt van verstopping, omdat je in deze periode
              geen stoelgang hebt.&nbsp;
            </p>
            <h3>Tip 5: Diarree kan besmettelijk zijn</h3>
            <p>
              Aangezien diarree door{" "}
              <a href="/feiten-over-diarree/buikgriep/">
                besmettelijke ziektekiemen (bacteriën of virussen, bv. bij
                gastro-enteritis)
              </a>
              &nbsp;veroorzaakt kan worden, is het mogelijk om iemand te
              besmetten met diarree. Infecties kunnen door contact met besmette
              personen, oppervlakken of voorwerpen of door het eten of drinken
              van besmette voedingswaren of water overgedragen worden. Handen
              wassen is dus belangrijk om besmetting te vermijden.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
